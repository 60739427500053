@value unit, secondary-color, font-size-smaller from '../../../../ring-globals.css';

.redirectTitle {
  composes: title from '../HttpsConfigurationPage.css';
}

.info {
  display: grid;
  grid-template-columns: calc(unit * 18) fit-content(calc(unit * 100));

  row-gap: calc(unit * 1.5);

  margin-bottom: calc(unit * 1.5);
}

.caption {
  margin-top: unit;

  color: secondary-color;

  font-size: font-size-smaller;
}
