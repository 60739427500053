@value unit, font-size, font-size-smaller, error-color, secondary-color, hover-background-color, icon-color from '../../../ring-globals.css';

.agent {
  display: flex;

  align-items: baseline;

  padding: 0 calc(4 * unit);

  line-height: calc(4 * unit);
}

.agent:hover {
  background-color: hover-background-color;
}

.loading {
  cursor: progress;
}

.loading-toggle {
  cursor: wait;
}

.expandable {
  cursor: pointer;
}

.expand-handle {
  margin: 0 calc(0.5 * unit) 0 calc(-2.25 * unit);
}

.toggle {
  margin-right: calc(unit);
}

.idle {
  color: secondary-color;
}

.disconnection-comment {
  white-space: nowrap;
}

.disconnected {
  color: error-color;
}

.idle,
.disconnected,
.disconnection-comment {
  font-size: font-size-smaller;
}

.running-placeholder {
  align-self: center;

  flex: 1 0;

  font-size: font-size-smaller;
}

.icon {
  margin-right: calc(unit / 2);
}

.link {
  font-size: font-size;
  line-height: calc(4 * unit);
}

.link,
.idle,
.split,
.disconnection-comment {
  flex: 0 0;
}

.link,
.idle,
.disconnection-comment,
.running-placeholder {
  margin-right: calc(2 * unit);
}

.status-comment {
  overflow: hidden;

  text-overflow: ellipsis;
}

.split {
  align-self: flex-start;

  height: calc(4 * unit);

  margin-left: auto;
}

.enable-timer,
.last-communication-timer {
  white-space: nowrap;
}

.unassign-button {
  padding: 0;

  color: icon-color;
}

.loader {
  align-self: center;
  flex-shrink: 0;
}

.enable-timer,
.last-communication-timer,
.authorize,
.unassign-button,
.loader {
  margin-left: unit;
}
